<template>
  <div class="sacombank">
    <img src="@/assets/images/Sacombank/Sacombank-header-img.jpg" alt="sacombank header" class="w-100 sacombank-banner d-none d-md-block">
    <img src="@/assets/images/Sacombank/BANNER-mobile.png" alt="sacombank header" class="w-100 sacombank-banner d-md-none">
    <div class="font-large-3 font-large-2-mb my-2 mt-md-3 container font-weight-bolder pb-md-4 border-bottom font-itel">
      <p class="text-center ">CHƯƠNG TRÌNH ƯU ĐÃI <span class="text-orange">MỞ THẺ TÍN DỤNG</span></p>
      <p class="text-center mt-md-3 ">DÀNH RIÊNG CHO <span class="text-orange">CBNV ITEL</span></p>
    </div>
    <div class="container mt-2">
      <p class="font-weight-bolder font-large-2 font-large-2-mb font-itel">CHÍNH SÁCH</p>
      <div class="row font-default font-medium-3-mb font-weight-bolder mt-2">
        <b-col cols="6" md="3" class="d-flex mb-1 mb-md-0">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="1" class="mr-1" style="width: 37px; height: 37px">
          </div>
          <div class="d-flex align-items-center">
            <div class=" ">
              Cấp ngay hạn mức thẻ
              lên đến <span class="text-orange">50 triệu đồng</span>
            </div>
          </div>
        </b-col>
        <b-col cols="6" md="3" class="d-flex mb-1 mb-md-0">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="1" class="mr-1" style="width: 37px; height: 37px">
          </div>
          <div class="d-flex align-items-center ">
            <div class="">
              <span class="text-orange">Miễn phí</span> thường niên trọn
              đời thẻ (*)
            </div>
          </div>

        </b-col>
        <b-col cols="6" md="3" class="d-flex">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="1" class="mr-1" style="width: 37px; height: 37px">
          </div>
          <div class="d-flex align-items-center ">
            <div class="">
              <span class="text-orange">Miễn phí</span> phát hành thẻ
            </div>
          </div>

        </b-col>
        <b-col cols="6" md="3" class="d-flex">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="1" class="mr-1" style="width: 37px; height: 37px">
          </div>
          <div class="d-flex align-items-center ">
            Tặng túi đeo chéo thể thao
          </div>
        </b-col>
      </div>
      <div class="mt-2">
        <span class="font-default text-orange">(*)</span> Khi phát sinh chi tiêu tối thiểu 1 triệu đồng trong vòng 30 ngày từ khi mở
        thẻ thành công, nhận ưu đãi miễn phí thường niên trọn đời với thẻ Visa Platinum hoặc ưu đãi hoàn 500,000 VND đối
        với thẻ Signature và Platinum Cashback.
      </div>
      <b-row class="mt-2 font-weight-bolder p-md-0 p-2">
        <b-col cols="12" class="p-2 p-md-3 condition font-medium-5 font-medium-3-mb mb-2 mb-md-0">
          <p class=" font-large-2 font-large-2-mb font-itel">ĐIỀU KIỆN ĐỂ ĐƯỢC MỞ THẺ</p>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="2" class="condition-tick">
            <div class="d-flex ml-1 align-items-center ">CBNV chính thức đã ký HĐLĐ kỳ hạn 12 tháng trở
              lên
            </div>
          </div>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="2" class="condition-tick">
            <div class="d-flex ml-1 align-items-center ">Đang làm việc tại Văn phòng Hà Nội</div>
          </div>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="2" class="condition-tick">
            <div class="d-flex ml-1 align-items-center ">Đã được cấp căn cước công dân (CCCD) gắn chip
            </div>
          </div>
          <p class="font-weight-bolder font-large-2 font-large-2-mb mt-1 font-itel">CHỨNG TỪ CUNG CẤP</p>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/SCB-tick.png" alt="2" class="condition-tick">
            <div class="d-flex ml-1 align-items-center ">CBNV cung cấp duy nhất CCCD</div>
          </div>
        </b-col>
        <b-col cols="12" class="p-2 p-md-3 receive-step font-medium-5 font-medium-3-mb">
          <p class="font-itel font-large-2 font-large-2-mb">QUY TRÌNH</p>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/step-1.png" alt="2"  class="step-image">
            <div class="d-flex ml-1 align-items-center w-100">Đăng Ký Chọn <span class="text-orange" style="margin-left: 0.5rem"> MỞ THẺ NGAY</span>
            </div>
          </div>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/step-2.png" alt="2" class="step-image">
            <div class="d-flex ml-1 align-items-center ">Cán Bộ Sacombank Tư Vấn Và Xác Nhận</div>
          </div>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/step-3.png" alt="2" class="step-image">
            <div class="d-flex ml-1 align-items-center ">Hoàn Thiện Thủ Tục
            </div>
          </div>
          <div class="d-flex mt-1 font-default">
            <img src="@/assets/images/Sacombank/step-4.png" alt="2" class="step-image">
            <div class="d-flex ml-1 align-items-center ">Bàn Giao Thẻ Tận Nơi Cho CBNV ITEL
            </div>
          </div>
        </b-col>
      </b-row>
      <p class="font-weight-bolder font-large-2 font-large-2-mb mt-4 font-itel">CÁC DÒNG THẺ ÁP DỤNG</p>
      <div class="d-md-flex mt-2 w-100 justify-content-between">
        <b-col cols="12" class="card-info p-2 pb-4 shadow rounded mb-1 mb-md-0">
          <img src="@/assets/images/Sacombank/platinum.png" alt="" class="w-100">
          <div class="d-flex justify-content-center">
            <p class="card-title p-1">Visa Platinum</p>
          </div>
          <div class="card-content font-medium-1 h-35">
            <ul>
              <li><b>Miễn lãi</b> lên tới <b>55 ngày</b></li>
              <li>Tích <b>điểm</b> đổi <b>quà</b></li>
              <li><b>Phòng chờ VIP</b> sân bay</li>
              <li><b>Bảo hiểm du lịch</b> toàn cầu</li>
              <li><b>Trả góp 0%</b> tại nhiều đối tác</li>
            </ul>
          </div>
          <div class="d-flex flex-column align-items-center">
            <a :href="platinum" class=" text-orange font-weight-bold font-medium-3"><u>Xem chi tiết</u></a>
            <button class="open-card-btn" @click="openLink">Mở thẻ ngay</button>
          </div>
        </b-col>
        <b-col cols="12" class="card-info p-2 pb-4 shadow rounded mb-1 mb-md-0">
          <img src="@/assets/images/Sacombank/signature.png" alt="" class="w-100">
          <div class="d-flex justify-content-center">
            <p class="card-title p-1">Visa Signature</p>
          </div>
          <div class="card-content font-medium-2 h-35">
            <ul>
              <li><b>Miễn lãi</b> lên tới <b>55 ngày</b></li>
              <li><b>Phòng chờ VIP</b> sân bay</li>
              <li><b>Bảo hiểm du lịch</b> toàn cầu</li>
              <li><b>Tích</b> dặm bay</li>
              <li><b>Tặng lên đến 5000 dặm</b> bay khi phát hành thẻ</li>
            </ul>
          </div>
          <div class="d-flex flex-column align-items-center">
            <a :href="signature" class=" text-orange font-weight-bold font-medium-3"><u>Xem chi tiết</u></a>
            <button class="open-card-btn" @click="openLink">Mở thẻ ngay</button>
          </div>
        </b-col>
        <b-col cols="12" class="card-info p-2 pb-4 shadow rounded mb-1 mb-md-0">
          <img src="@/assets/images/Sacombank/platinum-cashback.png" alt="" class="w-100">
          <div class="d-flex justify-content-center">
            <p class="card-title p-1">Visa Platinum Cashback</p>
          </div>
          <div class="card-content font-medium-2 h-35">
            <ul>
              <li><b>Miễn Lãi</b> Lên Tới <b>55 Ngày</b></li>
              <li><b>Trả Góp 0%</b> Tại Nhiều Đối Tác</li>
              <li><b>Hoàn 5%</b> Cho Giao Dịch Chi Tiêu Online, Phí Bảo Hiểm</li>
              <li><b>Hoàn 3%</b> Cho Giao Dịch Chi Tiêu Tại Nước Ngoài Qua Máy ...</li>
            </ul>
          </div>
          <div class="d-flex flex-column align-items-center">
            <a :href="platinumCashback" class=" text-orange font-weight-bold font-medium-3"><u>Xem chi tiết</u></a>
            <button class="open-card-btn" @click="openLink">Mở thẻ ngay</button>
          </div>
        </b-col>
      </div>
    </div>
    <div class="mt-5 pt-mb-4 py-2 footer-sacombank ">
      <div class="container d-md-flex">
        <div class="footer-content">
          <p class="font-weight-bold">Thông tin hỗ trợ: Sacombank</p>
          <div class="d-flex font-medium-5 font-medium-1-mb">
            <p>Nguyễn Thị Tâm</p>
            <p class="ml-2">0982 953 354</p>
            <p class="ml-2">tamnt23624@sacombank.com</p>
          </div>
        </div>
        <div class="footer-content">
          <p class="font-weight-bold">Thông tin hỗ trợ: ITEL</p>
          <div class="d-flex  font-medium-5 font-medium-1-mb">
            <p>Nguyễn Đức Việt</p>
            <p class="ml-2">087 9999 359</p>
            <p class="ml-2">vietnd@itelecom.vn</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue";

export default {
  name: "Sacombank",
  components: {BCol, BRow},
  data() {
    return {
      registerLink: 'https://forms.office.com/pages/responsepage.aspx?id=Bouaq-BCYE-xop46PHinjOIB949nSiRHhNuJSFVqTDpUQzhBREdCRENSQllJM1hRRzhBMlhFNkMyTi4u',
      platinum: 'https://www.sacombank.com.vn/ca-nhan/the/the-tin-dung/the-tin-dung-sacombank-visa-platinum.html',
      signature: 'https://www.sacombank.com.vn/ca-nhan/the/the-tin-dung/the-tin-dung-sacombank-visa-signature.html',
      platinumCashback: 'https://www.sacombank.com.vn/ca-nhan/the/the-tin-dung/the-tin-dung-sacombank-visa-platinum-cashback.html'
    }
  },
  methods: {
    openLink() {
      window.open(this.registerLink, '_blank')
    }
  },
  mounted() {
    const intervalFooter = setInterval(() => {
      if(window.hiddenFooter){
        window.hideChatBot(true);
        window.hiddenHeader(false)
        window.hiddenFooter(false)
        clearInterval(intervalFooter)
      }
    }, 100)
  }
}
</script>

<style scoped>
.sacombank {
  color: #140342;
  line-height: 2;
  font-size: 15px;
}
.h-35{
  height: 320px;
}
.text-orange {
  color: #ED683B;
}
.sacombank-banner{
  width: 100%;
  height: 60vh;
}
ul li{
  list-style: disc;
  font-size: 1.8rem;
  font-weight: 300;
}
.bg-orange {
  background: #ED683B;
}

.condition {
  background: #ffefea;
  border-radius: 1rem;
}

.condition-tick{
  width: 37px;
  height: 37px;
}

.receive-step {
  background: #ffefea;
  border-radius: 1rem;
}

.card-title {
  border: 1px #ED683B solid;
  font-weight: 800;
  font-size: 1.8rem;
  border-radius: 8px;
  width: fit-content;
  margin-top: 1rem
}

.open-card-btn {
  background: #ED683B;
  border-radius: 50px;
  border: 1px #ED683B solid;
  color: white;
  font-weight: 800;
  font-size: 1.7rem;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  width: fit-content;
}
.font-default{
  font-size: 1.8rem;
}
.open-card-btn:hover {
  background: #ffefea;
  color: #ED683B;
  border: 1px #ED683B solid;
  transition: color 0.2s;
}

.footer-sacombank {
  background: #ffefea;
  border-top: #DBC3AD 2px solid;
  font-size: 1.7rem !important;
  font-weight: 400;

}

.card-info:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
  scale: 1.05;
  transition: scale 0.2s;
}
.support-footer{
  width: 65%;
}
.footer-content{
  width: 55% ;
}

.sacombank .container{
  width: 80vw;
  max-width: 80vw;
}

.card-content{
  padding-right: 15%;
  padding-left: 15%;
}

@media screen and (min-width: 767px ) {
  .condition {
    max-width: 45% !important;
    margin-right: 4%;
  }

  .receive-step {
    max-width: 50% !important;
  }

  .card-info {
    max-width: 30%;
  }
}

@media screen and (max-width: 767px ) {
  .sacombank {
    font-size: 12px;

    .font-large-2-mb {
      font-size: 1.5rem !important;
    }

    .font-medium-3-mb {
      font-size: 1.1rem !important;
    }
    .font-large-1-mb{
      font-size: 1.3rem !important;
    }
    .font-large-1 {
      font-size: 1.3rem !important;
    }
    .font-default{
      font-size: 1.1rem !important;
    }
    .sacombank-banner{
      height: auto;
    }
    .step-image{
      width: 46px;
      height: 46px;
    }
    .footer-sacombank{
      font-size: 1.4rem !important;
    }
    .font-medium-1-mb{
      font-size: 1.2rem !important;
    }
    .support-footer{
      width: 100%;
    }
    .footer-content{
      width: 100% ;
    }
    .container{
      width: 100vw;
      max-width: 100vw;
    }
  }
}

</style>

<style>
.font-itel{
  font-family: "iTel", sans-serif;
}
html {
  font-size: 15px;
}
.font-weight-bolder {
  font-weight: 700 !important;
}
@media screen and (max-width: 767px ) {
  html {
    font-size: 12px;
  }
  html body p {
    line-height: 2.5rem;
  }
}
</style>
